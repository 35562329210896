import type { AppProps } from 'next/app';
import { I18nProvider } from 'next-localization';
import dynamic from 'next/dynamic';
import { SitecorePageProps } from 'src/jss/lib/page-props';
import { JumpToMainContent, ThemeContainer } from '@ads-core/components';
import { AdsProvider, DialogProvider } from '@ads-core/providers';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useRouter } from 'next/router';
import React from 'react';
import { WidgetsProvider } from '@sitecore-search/react';
import Script from 'next/script';
import NextImage from 'next/image';
import { InternalOrExternalLink } from 'src/components/InternalOrExternalLink';
import { AltLink, Robots, SitecoreImage, StructuredData } from '@alliander-fe/sitecore-types';
import { Field, LayoutServiceData, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  SitecoreContextProvider,
  TrackingContextProvider,
  useTrackingContext,
} from '@liander/context';
import { reactPlugin } from './../AppInsights';
import '../global.css';

// We create a seperate InnerApp component, so we can leverage context on this level
function InnerApp({ children }: { children: React.ReactNode }) {
  const oldPathname = React.useRef<string>();
  const router = useRouter();
  const { trackPageChange } = useTrackingContext();

  // Populate the oldPathname ref with the current pathname
  React.useEffect(() => {
    oldPathname.current = window.location.pathname;
  }, []);

  React.useEffect(() => {
    const handleRouteChange = (): void => {
      if (oldPathname.current === window.location.pathname) {
        return;
      }

      trackPageChange();
      document.getElementById('skip-to-content')?.focus();
      oldPathname.current = window.location.pathname;
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.query, trackPageChange]);

  return children;
}

export type LayoutProps = {
  layoutData: LayoutServiceData & {
    sitecore: {
      route?: RouteData<{ pageTitle: Field; image: SitecoreImage; itemLanguage: Field }>;
      context: {
        gtmContainerId: string;
        alternateLinks: AltLink[];
        metaTitle: string;
        metaKeywords: string;
        metaDescription: string;
        pageType: string;
        image: SitecoreImage;
        structuredData: StructuredData;
        Robots?: Robots;
        tag: string;
        enableSearchSuggestions: boolean;
        editorMode: boolean;
      };
    };
  };
};

export type SitecorePagePropsEnriched = SitecorePageProps & LayoutProps;

function App({ Component, pageProps }: AppProps<SitecorePagePropsEnriched>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  const apiKey: string = process.env.NEXT_PUBLIC_SITECORE_SEARCH_APIKEY ?? '';
  const customerKey: string = process.env.NEXT_PUBLIC_SITECORE_SEARCH_CUSTOMER_KEY ?? '';

  return (
    // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
    // Note Next.js does not (currently) provide anything for translation, only i18n routing.
    // If your app is not multilingual, next-localization and references to it can be removed.
    <AppInsightsContext.Provider value={reactPlugin}>
      <WidgetsProvider env="prodEu" customerKey={customerKey} apiKey={apiKey}>
        <SitecoreContextProvider {...rest.layoutData?.sitecore}>
          <TrackingContextProvider>
            <DialogProvider>
              <AdsProvider
                components={{
                  a: (props) => <InternalOrExternalLink {...props} />,
                  img: (props) => (
                    <NextImage
                      {...props}
                      quality={100}
                      sizes="(min-width: 320px) 100vw, (min-width: 768px) 80vw, (min-width: 1024px) 50vw, 100vw"
                    />
                  ),
                }}
              >
                <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
                  <ThemeContainer>
                    <InnerApp>
                      <JumpToMainContent />
                      <DynamicSessionProvider>
                        <Component {...rest} />
                      </DynamicSessionProvider>
                      <Script src={process.env.NEXT_PUBLIC_ADOBE_ANALYTICS_SCRIPT} async />
                    </InnerApp>
                  </ThemeContainer>
                </I18nProvider>
              </AdsProvider>
            </DialogProvider>
          </TrackingContextProvider>
        </SitecoreContextProvider>
      </WidgetsProvider>
    </AppInsightsContext.Provider>
  );
}

const DynamicSessionProvider = dynamic(() => import('../utils/provider').then((mod) => mod.NextAuthSession))

export default App;
